import React from 'react'
import { Link, StaticQuery, graphql } from 'gatsby'
import * as style from '../styles/theme/nav.module.scss'

const makeLink = (name) => `/${name.toLowerCase().replace(' ', '-')}`

const renderNav = () => ({ site }) => (
  <nav className={ style.container }>
    { site.siteMetadata.nav.map((name) => <Link className={ style.link } to={ makeLink(name) }>{ name }</Link>) }
  </nav>
);

const Nav = () => (
  <StaticQuery
    query={ graphql`
      query {
        site {
          siteMetadata {
            nav
          }
        }
      }`
    }
    render={ renderNav() }
  />
)

export default Nav
