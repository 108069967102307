import React from 'react'

import * as style from '../styles/theme/footerBlock.module.scss'

const FooterBlock = ({ title, children }) => (
  <div className={style.footerBlock}>
    <h1>{title}</h1>
    {children}
  </div>
)

export default FooterBlock
