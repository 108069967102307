import React from 'react'
import { Link } from 'gatsby'
import classnames from 'classnames'

import Nav from '../components/nav'
import * as style from '../styles/theme/header.module.scss'

const Header = ({ title, home }) => {
  const classes = classnames({
    [style.header]: true,
    [style.home]: home,
  })
  return (
    <header className={ classes }>
      <h1><Link to="/">{ title }</Link></h1>
      <Nav />
    </header>
  )
}

export default Header
