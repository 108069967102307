import React from 'react'

import Layout from '../components/layout'
import ZoomablePhoto from '../components/ZoomablePhoto'
import * as style from '../styles/theme/gallery.module.scss'

const Gallery = () => (
  <Layout>
    <h1>Photo gallery</h1>
    <p>Our gallery is still under construction. Visit again soon for more photos of our beautiful dogs and be sure to follow us on
      &nbsp;
      <a href="https://instagram.com/wilderborngsd?igshid=1qi9cya9jlsyp" title="instagram" target="_blank">Instagram</a>,&nbsp;
      <a href="https://www.youtube.com/channel/UCZSjqnpGCvDheFCrv8lyIrw" title="YouTube channel" target="_blank">YouTube</a> and&nbsp;
      <a href="https://fb.me/wilderborngsd" title="Facebook page" target="_blank">Facebook</a>!
    </p>

    <h2>Parents</h2>
    <h3>Thor</h3>
    <div className={ style.section }>
      <ZoomablePhoto src="/thor-4wd.jpg" title="Thor (sire)" />
      <ZoomablePhoto src="/thor.jpg" title="Thor (sire)" />
    </div>
    <h3>Eva</h3>
    <div className={ style.section }>
      <ZoomablePhoto src="/eva.jpg" title="Eva (dam)" />
      <ZoomablePhoto src="/eva2.jpg" title="Eva (dam)" />
    </div>
    <h2>Puppies</h2>
    <h3>Neo</h3>
    <div className={ style.section }>
      <ZoomablePhoto src="/koda2.jpg" title="Neo - 10 weeks" />
      <ZoomablePhoto src="/koda.jpg" title="Neo - 10 weeks" />
      <ZoomablePhoto src="/neo.jpg" title="Neo 1yo" />
      <ZoomablePhoto src="/neo2.jpg" title="Neo 1yo" />
    </div>
    <h3>Jasper</h3>
    <div className={ style.section }>
      <ZoomablePhoto src="/pup.jpg" title="Jasper - 8 weeks" />
      <ZoomablePhoto src="/jasper-fishing.jpg" title="Jasper out fishing!" />
      <ZoomablePhoto src="/jasper-puppy.jpg" title="Jasper" />
      <ZoomablePhoto src="/jasper-1yo.jpg" title="Jasper 1yo" />
    </div>
    <h3>Ada</h3>
    <div className={ style.section }>
      <ZoomablePhoto src="/ada-pup.jpg" title="puppy Ada"/>
      <ZoomablePhoto src="/ada2.jpg" title="Ada - 10 weeks"/>
      <ZoomablePhoto src="/ada.jpg" title="Ada"/>
    </div>
    <h3>Freckles</h3>
    <div className={ style.section }>
      <ZoomablePhoto src="/freckles-pup.jpg" title="Little Freckles" />
    </div>
    <h3>Diesel</h3>
    <div className={ style.section }>
      <ZoomablePhoto src="/diesel.jpg" title="Diesel"/>
    </div>
  </Layout>
)

export default Gallery
