import React from 'react'
import { StaticQuery, graphql } from "gatsby"
import classnames from 'classnames'

import Header from './header'
import Footer from './footer'

import '../styles/global.scss'
import * as css from '../styles/theme/layout.module.scss'

const renderLayout = ({ children, home, header }) => (data) => {
  // const innerClassNames = classnames({
  //   [css.inner]: true,
  //   [css.home]: home,
  // })
  const contentClassNames = classnames({
    [css.content]: true,
    [css.home]: home,
  })
  return (
    <div className={ css.container }>
      <Header title={ data.site.siteMetadata.title } home={ home } />
      <div className={ contentClassNames }>
        { children }
      </div>
      <Footer />
    </div>
  )
}

const Layout = (props) => (
  <StaticQuery
    query={ graphql`
      query {
        site {
          siteMetadata {
            title
          }
        }
      }`
    }
    render={ renderLayout(props) }
  />
)

export default Layout
