import React from 'react'
import { Link } from 'gatsby'
import FooterBlock from './footerBlock'
import * as style from '../styles/theme/footer.module.scss'

const Footer = () => (
  <footer className={style.footer}>
    <FooterBlock title="Kennel">
      <Link to="/about">About us</Link>
      <Link to="/contact">Contact us</Link>
      <Link to="/faq">FAQ</Link>
    </FooterBlock>
    <FooterBlock title="Puppies">
      <Link to="/testamonials">Testamonials</Link>
      <Link to="/puppies">Puppies available</Link>
      <Link to="/gallery">Photo gallery</Link>
    </FooterBlock>
    <FooterBlock title="Follow Us">
      <a
        href="https://instagram.com/wilderborngsd?igshid=1qi9cya9jlsyp"
        title="instagram"
        target="_blank"
        rel="noreferrer"
      >
        Instagram
      </a>
      <a
        href="https://www.youtube.com/channel/UCZSjqnpGCvDheFCrv8lyIrw"
        title="YouTube channel"
        target="_blank"
        rel="noreferrer"
      >
        YouTube
      </a>
      <a
        href="https://fb.me/wilderborngsd"
        title="Facebook page"
        target="_blank"
        rel="noreferrer"
      >
        Facebook
      </a>
    </FooterBlock>
  </footer>
)

export default Footer
